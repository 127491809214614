.refresh-offers-loading-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 296px;
    gap: 30px;
    position: relative;
    width: fit-content;
    box-sizing: border-box;
    background: #FFFFFF;
    margin: 0 auto;
}

@media (max-width: 490px) {
    .refresh-offers-loading-container {
        padding: 40px 20px 296px;
    }
}

.refresh-offers-loading-container .title {
    margin: 187px 0 0 0;
    font-weight: 600;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: #2A2F31;
    align-self: center;
    white-space: nowrap;
}

@media (max-width: 490px) {
    .refresh-offers-loading-container .title {
        font-size: 24px;
        line-height: 32px;
    }
}