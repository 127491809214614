.offer-block {
  border-radius: 16px;
  border: 2px solid #808080;
  background-color: #ffffff;
  padding-bottom: 53px;
  position: relative;
  overflow: hidden;
  color: #2A2F31;
  container-name: offer-block;
  container-type: inline-size;
}

.offer-block .details {
  padding: 18px 10px 20px;
  color: #182B54;
  user-select: none;
  text-align: center;
  gap: 10px;
  min-height: 194px;
  max-width: 464px;
  margin: 0 auto;
}

.offer-block .details .provider-logo {
  display: block;
  height: 36px;
  margin: 10px auto;
}

.offer-block .details .provider-logo.expired {
  filter: grayscale(100%);
}

.offer-block .accordion-toggle {
  position: absolute;
  inset: auto 0 0 0;
  height: 53px;
  background-color: #E6E6E6;
  overflow: hidden;
  transform: rotate(0);
}
.offer-block .accordion-toggle .ac-cont {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}

.offer-block .accordion-toggle .toggle-icon{
  margin: 1px 0 -3px;
}

.offer-block .accordion-toggle .toggle-icon svg {
  vertical-align: top;
}

.offer-block .accordion-toggle .toggle-text {
  font-size: 10px;
  line-height: 10px;
  color: #2A2F31;
  font-weight: 600;
}

.offer-block.hide-toggle {
  padding-bottom: 0;
}
.offer-block.hide-toggle .accordion-toggle {
  display: none;
}

.offer-block .toggle-info {
  display: flex;
  margin: -4px auto;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.offer-block .toggle-info .toggle-badge{
  display: flex;
  gap: 4px;
  align-items: center;
}   

.offer-block .toggle-info .toggle-expire{
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 0;
  min-height: 28px;
}   

.offer-block .toggle-info .toggle-expire .information-text {
  color: #E3342F;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.offer-block .toggle-info .toggle-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  text-align: center;
}

.offer-block.open .show-on-hidden {
  display: none;
}

.offer-block .show-on-expanded {
  display: none;
}

.offer-block.open .show-on-expanded {
  display: block;
}

.offer-block .toggle-info svg {
  display: block;
  margin: -4px 0;
}

.offer-block .toggle-info .toggle-badge .text {
  font-size: 14px;
  color: #2A2F31;
  line-height: 20px;
  font-weight: 600;
}

.offer-block .expansion-panel{
  padding: 0 20px 40px;
}

.offer-block .description-block {
  padding: 20px 0;
  border-top: 2px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.offer-block .description-header {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.18px;
  color: #2A2F31;
  text-align: center;
}

.offer-block .facts-block {
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
}

.offer-block .fact-block-line {
  gap: 5px;
  display: flex;
  align-items: center;
}
.offer-block .fact-block-line + .fact-block-line {
  margin-top: 8px;
}

.offer-block .fact-block-line svg{
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin: -4px 0;
}

.offer-block .icon-cols {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.offer-block .icon-col {
  flex: 0 0 auto;
  width: 100px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.offer-block .icon-col svg,
.offer-block .icon-col img{
  vertical-align: top;
  height: 40px;
}

.offer-block .metric-tag {
  display: flex;
  width: 116px;
  height: 20px;
  border-radius: 12px;
  background: linear-gradient(180deg, #68CAE8 0%, #3080DE 100%);
  font-weight: 700 !important;
  font-size: 12px !important;
  text-align: center;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
}

@container offer-block (width < 450px) {
  .offer-block .metric-tag { 
    width: 82px;
    font-size: 7px !important;
    font-weight: 800 !important;
  }
}
@container offer-block (width < 415px) {
  .offer-block .toggle-info {
    gap: 15px;
  }
  .offer-block .toggle-info .toggle-badge .text,
  .offer-block .toggle-info .toggle-expire .information-text { 
    font-size: 10px !important;
    line-height: 12px;
  }
}