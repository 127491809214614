.refresh-offers-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    position: relative;
    width: fit-content;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.title {
    align-self: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #2A2F31;
}

@media (max-width: 490px) {
    .title {
        font-size: 24px;
        line-height: 32px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
}

.top-align {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 16px;
}

.middle-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;
}

.subheader {
    /* width: 464px; */ /* if you need text wrapping as in Figma */
    align-self: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2A2F31;
}

@media (max-width: 490px) {
    .subheader {
        font-size: 16px;
        line-height: 24px;
    }
}

.divider {
    align-self: center;
    gap: 10px;
    padding: 0px 172px;
    height: 0px;
    border-top: 2px solid #999999;
}

@media (max-width: 490px) {
    .divider {
        padding: 0px 120px;
    }
}

@media (max-width: 390px) {
    .divider {
        width: 180px;
        padding: 0;
    }
}

.contact-block {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 30px;
}

.support, .details {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 0px 20px;
    gap: 7px;
}

.call-title-block, .details-title-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
}

.call-title, .details-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #2A2F31;
}

.call-subtitle-block {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 7px;
}

.call-subtitle, .head {
    /* width: 424px; */ /* if you need text wrapping as in Figma */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2A2F31;
}

.call-phone {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2BACE2;
    text-decoration: none;
}

.MuiTypography-root.carwiser-link {
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2BACE2;
}

.button {
    margin-top: 153px;
    text-align: center;
}

@media (max-width: 490px) {
    .button {
        margin-top: 60px;
    }
}

.button button {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: 0.01em;
}

.question-block {
    width: 100%
}

.question-label, .question-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #2A2F31;
}

@media (max-width: 490px) {
    .question-label, .question-title {
        font-size: 16px;
        line-height: 24px;
    }
}

.or-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2A2F31;
    margin: 10px 0;
}
.hint {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    margin-top: 10px;
    color: #2A2F31;
}