.input-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 10px;
  }

  .input-wrapper .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .input-wrapper label.MuiInputLabel-root {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #B3B3B3;
    margin-left: 8px;
    font-family: 'Montserrat', sans-serif;
  }

  .input-wrapper .MuiInputLabel-root.MuiInputLabel-shrink {
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: #808080;
    margin-top: 6px;
    margin-left: 10px;
  }

  .input-wrapper .MuiInputLabel-root.MuiInputLabel-shrink + div .MuiFilledInput-input.MuiInputBase-input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    height: 22px;
    padding-left: 20px;
  }

  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root {
    background-color: #FFFFFF;
    border: 1px solid #808080;
    box-sizing: border-box;
    border-radius: 7px;
    padding-left: 0px;
    margin-top: 2px;
  }

  .input-wrapper .MuiFilledInput-input.MuiInputBase-input {
    padding-top: 21px;
    border-radius: 6px;
  }

  .input-wrapper .MuiInputLabel-root.Mui-focused {
    color: #2BACE2;
  }

  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.Mui-focused {
    box-sizing: border-box;
    border: 2px solid #2BACE2;
  }

  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root::before,
  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root::after {
    border: none;
  }

  .input-wrapper .MuiInputLabel-root.Mui-focused.Mui-error {
    color: #E3342F;
  }

  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.Mui-error {
    border: 2px solid #E3342F;
  }

  .input-wrapper .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.Mui-focused.Mui-error {
    border: 2px solid #E3342F;
  }

  .input-wrapper .MuiFormHelperText-root.Mui-error, .input-wrapper .bottom-helper-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.035em;
    color: #E3342F;
    margin: 3px 0 0;
  }
  .input-wrapper .bottom-helper-text {
    margin: 4px 0 0;
    color: #2A2F31;
    line-height: 16px;
    font-weight: normal;
  }

  .input-wrapper .errorImg {
    position: absolute;
    height: 28px;
    top: 15px;
    right: 20px;
  }

  .input-wrapper .MuiFilledInput-root .Mui-disabled {
    background-color: #E6E6E6;
    border-radius: 6px;
  }