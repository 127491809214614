.spinner-container {
    display: flex;
    justify-content: center;
}

.spinner-container .wheel {
    position: relative;
    width: 160px;
    height: 160px;
}

.spinner-container .wheel .background,
.spinner-container .wheel .inside {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.spinner-container .wheel .inside {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
