.cntx-container.MuiContainer-root {
    /* display: flex; */
    align-content: center;
}

.cntx-container > .MuiBox-root {
    width: 800px;
    margin: auto;
    /* display: flex; */
}

.mobile .cntx-container > .MuiBox-root {
    width: auto;
}

.cntx-container .title {
    color: #2A2F31;
}

.cntx-container h1.title {
    padding: 40px 0 20px 0;
    margin: 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
}

.mobile .cntx-container h1.title {
    padding: 18px 0 16px 0;
    font-size: 20px;
    line-height: 28px;
}

.cntx-container h3.title {
    padding-bottom: 8px;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

.mobile .cntx-container h3.title {
    font-size: 16px;
    line-height: 24px;
}

.cntx-container form .input-wrapper {
    margin-top: 0;
    width: 100%;
}

.cntx-container form .fields .row {
    margin: 12px 0 0 0;
}

.mobile .cntx-container form .fields .row {
    margin: 8px 0 0 0;
}

.cntx-container form .fields {
    width: 464px;
    margin: auto;
}

.mobile .cntx-container form .fields {
    width: auto;
}

.cntx-container form .fields .MuiInputBase-root {
    height: 54px;
}

.cntx-container form .buttons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.mobile .cntx-container form .buttons {
    margin-top: 32px;
    justify-content: center;
}

.cntx-container form .buttons > .MuiButton-root {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 20px;
    line-height: 28px;
    width: 136px;
}

.cntx-container form .buttons > .MuiButton-root.first {
    margin-right: 10px;
    width: 164px;
}