.header-wrapper {
    background: #2A2F31;
    z-index: 1;
    height: 100px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.header-wrapper .logo-wrapper {
    padding-left: 40px;
}

.header-wrapper .menu-logo-wrapper {
    padding-right: 40px;
}
