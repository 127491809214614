.form-select {
  width: 100%;
  position: relative;
  display: inline-block;
}

.form-select .errorImg {
  top: 12px;
  right: 46px;
  pointer-events: none;
}

.form-select .MuiSvgIcon-root {
  display: none;
}

.form-select .arrow {
  display: block;
  position: absolute;
  right: 12px;
  top: 11px;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

.form-select label.MuiInputLabel-root {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #B3B3B3;
  margin-left: 8px;
  font-family: 'Montserrat', sans-serif;
  margin-top: -2px;
}

.form-select .MuiFormHelperText-root {
  margin: 3px 0 0;
}
.form-select.has-error .MuiFormHelperText-root {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.035em;
  color: #E3342F;
  margin: 3px 0 0;
}

.form-select .MuiInputLabel-root.MuiInputLabel-shrink {
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #808080;
  margin-left: 8px;
  transform: translate(12px, 6px) scale(0.8);
}

.form-select .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused {
  color: #2BACE2;
}

.form-select .MuiSelect-select{
  height: 54px;
  min-height: 54px !important;
  background-color: #FFFFFF;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 21px 20px 7px;
  font-size: 18px;
  color: currentColor;
}

.form-select.has-error .MuiSelect-select {
  border-color: #E3342F;
  border-width: 2px;
  padding: 20px 19px 6px;
}

.form-select .MuiSelect-select.Mui-disabled {
  background-color: #E6E6E6;
  border-radius: 7px;
}

.form-select .Mui-focused .MuiSelect-select {
  border-color: #2BACE2;
  border-width: 2px;
  padding: 20px 19px 6px;
}

.form-select .MuiOutlinedInput-notchedOutline{
  display: none;
}