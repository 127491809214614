.date-picker {
    position: relative;
}

.date-form {
    max-width: 100%;
}

@media (min-width: 520px) {
    .date-form {
        width: 464px;
    }
}

.date-picker .MuiButtonBase-root{
    position: absolute;
    inset: 0;
    opacity: 0;
    border-radius: 0;
}

.offer-form {
    color: #3E3E40;
}

.offer-form .block-header{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 10px 0 0;
}

.offer-form .block-header.subheader {
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
}

.offer-form .block-header.center {
    text-align: center;
}

.offer-form .hours-table {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
}

.offer-form .hours-table td {
    padding: 2px 30px 2px 0;
    border: 0;
}
.offer-form .hours-table td:last-child {
    padding-right: 0s;
}

.offer-form .location {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
}

.offer-form .location p{
    margin: 0 0 5px;
}

.offer-form .date-form {
    margin-top: 10px;
}

.offer-form .date-form p {
    margin: 10px 0 0;
}

.offer-form .date-form .form-btns{
    text-align: center;
    margin-top: 30px;
}

.offer-form .col {
    display: flex;
    justify-content: center;
}

.offer-form .mw {
    width: 464px;
    max-width: 100%;
}

.offer-form .semibold {
    font-weight: 500;
}

.offer-form .input-wrapper .MuiInputLabel-root.MuiInputLabel-shrink + div .MuiFilledInput-input.MuiInputBase-input {
    margin: 0;
}