.MuiDrawer-paper .drawer-list {
    width: 100%;
}

.MuiDrawer-paper .drawer-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.drawer-list .drawer-header {
    margin: 40px 20px 20px 20px;
}

.drawer-header .MuiAvatar-root {
    color: white;
    background: #00AEEF;
    font-weight: bold;
    margin-bottom: 16px;
}

.drawer-header .username {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.drawer-header .email {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.drawer-list .MuiDivider-root {
    /* border: 3px solid #808080; */
    background-color: #808080;
    height: 3px;
    border: 0px;
}

.drawer-list .main-actions {
    padding-top: 10px;
    padding-bottom: 10px;
}

.drawer-list .main-actions .MuiButtonBase-root {
    padding-top: 10px;
    padding-bottom: 10px;
}

.drawer-list .main-actions .MuiListItemIcon-root {
    min-width: 34px;
}

.drawer-list .main-actions .MuiListItemText-root {
    margin: 0px;
}

.drawer-list .main-actions .MuiTypography-root {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.drawer-icon {
    color: #96979C;
}