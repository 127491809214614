.main-wrapper .login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #2A2F31;
    text-align: center;
    position: relative;
    min-height: 100vh;
    max-width: 512px;
    min-width: 391px;
}

.login-wrapper .logo-wrapper {
    padding: 160px 0 20px 0;
}

.login-wrapper .login-line {
    padding-top: 8px;
    width: 180px;
    display: inline-block;
    border-top: 1px solid #2BACE2;
}

.login-wrapper .login-title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.login-wrapper .login-sub-title {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

@media (min-width: 496px) {
    .login-wrapper .login-input-wrapper {
        width: 464px;
        margin: auto;
    }
}

.login-wrapper .login-button-wrapper {
    margin: 40px 0 25px 0;
}

.login-wrapper .password-text {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2BACE2;
    text-decoration: none;
}

.login-wrapper .login-content {
    padding-bottom: 32px;
    flex: 1 1 auto;
}

.message-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 13px 15px 0 15px;
}

.message-container .MuiAlert-root {
    overflow-wrap: anywhere;
}

.message-container .MuiAlert-root .MuiAlert-icon {
    align-items: center;
}

.message-container .message-text {
    color: rgb(250, 179, 174);
    align-items: center;
}

.message-container .error-title {
    text-align: left;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.015em;
    font-size: 16px;
}

.message-container .error-description {
    text-align: left;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.017em;
    font-size: 14px;
}

.login-wrapper .MuiButton-root {
    width: 100%;
    margin-bottom: 20px;
    line-height: 28px;
    font-size: 20px;
    padding: 16px 25px;
}

.login-wrapper .MuiButton-root:disabled {
    font-weight: 600;
    color: #2A2F31;
}

.login-wrapper .MuiButton-root.margin-bottom-10 {
    margin-bottom: 10px;
}

.button-description {
    margin-bottom: 20px;
    color: #FFF;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-weight: 400;
}

.button-description-top {
    margin: 40px 0 10px;
    color: #FFF;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-weight: 400;
}

.button-description a {
    font-weight: 700;
    color: #2BACE2;
    padding-left: 4px;
    text-decoration: none;
    cursor: pointer;
}

.login-wrapper .MuiButton-outlined:hover {
    background-color: #FFF;
}

.login-wrapper .button-divider {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    font-weight: 400;
    color: #FFF;
    margin: 0 0 20px 0;
}

.login-wrapper .input-wrapper {
    margin: 0px;
}

.login-wrapper .input-wrapper + .input-wrapper {
    margin-top: 20px;
}

.login-wrapper .login-input-wrapper {
    margin: 40px 0 20px;
}

.login-wrapper .login-input-wrapper .MuiInputBase-input {
    height: 22px;
    padding-top: 24px
}

.message-container {
    padding: 0 0 20px 0;
}

.message-container .MuiAlert-root {
    width: 100%;
    border-radius: 4px;
    padding: 6px 16px;
    background-color: #FDEDED;
    color: #5F2120;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 0.015em;
    font-weight: 500;
}

.message-container .MuiAlert-root .MuiAlert-icon {
    width: 22px;
    height: 22px;
    margin-top: 7px;
}

/* .message-container .MuiAlert-root .expired-resend-button {
    width: 22px;
    height: 22px;
    margin-top: 7px;
} */

.alert-resend-code-box .MuiAlert-message {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.alert-resend-code-box .MuiAlert-message .expired-resend-button {
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 0.0046em;
    line-height: 22px;
    font-weight: 500;
    margin-right: 5px;
    color: #5F2120;
    text-decoration: none;
}

