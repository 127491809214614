/* html, body, #root, .auth {
  height: 100%;
} */

body {
  margin: 0;
  color: #2A2F31;
}

body.auth, .MuiScopedCssBaseline-root.auth {
  background-color: #2A2F31 !important;
}

.mobile .MuiButton-root {
  font-size: 20px;
  line-height: 28px;
}