.login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #2A2F31;
    text-align: center;
    position: relative;
    min-height: 100vh;
}

.login-wrapper .logo-wrapper {
    padding-top: 160px;
}

.login-wrapper .login-line {
    padding-top: 8px;
    width: 180px;
    display: inline-block;
    border-top: 1px solid #2BACE2;
}

.login-wrapper .login-title {
    margin-bottom: 13px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.login-wrapper .login-title-short {
    margin: 0 auto 13px auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    max-width: 800px;
}

.login-wrapper .login-title-v2 {
    margin-bottom: 13px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.login-wrapper .login-sub-title {
    margin-bottom: 13px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

@media (min-width: 496px) {
    .login-wrapper .login-input-wrapper {
        width: 464px;
        margin: auto;
    }
}

.login-wrapper .login-button-wrapper {
    margin: 25px 0;
}

.login-wrapper .password-text {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2BACE2;
    text-decoration: none;
}

.login-wrapper .login-content {
    padding-bottom: 32px;
    flex: 1 1 auto;
}

.message-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 13px 15px 0 15px;
}

.message-container .MuiAlert-root {
    overflow-wrap: anywhere;
}

.message-container .MuiAlert-root .MuiAlert-icon {
    align-items: center;
}

.message-container .message-text {
    color: rgb(250, 179, 174);
    align-items: center;
}