.main-content {
    min-height: 730px;
}

.mobile .main-content {
    min-height: 632px;
}

.not-accepted-wrapper, .accepted-wrapper {
    /* todo: common styles */
    background-color: #fff;
    color: #2A2F31;
}

.car-name, .car-trim, .car-line-info {
    text-transform: uppercase;
}

.not-accepted-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.not-accepted-wrapper .title {
    margin-top: 40px;
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #2A2F31;
    letter-spacing: -0.02em;
}

.not-accepted-wrapper .not-accepted-button-wrapper {
    margin: 30px 0 60px;
}

.not-accepted-wrapper .not-accepted-content {
    align-self: center;
    margin-top: 100px;
    flex: 1 0 auto;
}

.not-accepted-wrapper .not-accepted-big-footer-wrapper {
    flex-shrink: 0;
}

.not-accepted-content .content-block {
    width: 1200px;
}

.not-accepted-content .first-block-with-border {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 82px;
    align-items: center;
    border-width: 3px;
    border-style: solid;
    border-color: #808080;
    border-radius: 24px;
    width: 1200px;
    height: 480px;
    box-sizing: border-box;
    padding: 0px 63px 0px 27px;
}

.not-accepted-content .left-block {
    margin-top: 60px;
    margin-left: -67px;
}

.not-accepted-content .right-block {
    text-align: left;
}

.not-accepted-content .ribbon {
    position: absolute;
    top: 42px;
    left: 92px;
}

.not-accepted-content .car-name {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #2A2F31;
}

.not-accepted-content .subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #2A2F31;
}

.not-accepted-content .information-block {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.not-accepted-content .type-of-information {
    display: inline-block;
    margin-right: 8px;
    font-weight: 700;
}

.not-accepted-content .accordions-block {
    column-gap: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
}

.accepted-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.accepted-wrapper .accepted-button-wrapper {
    margin: 30px 0 60px;
}

.accepted-wrapper .accepted-content {
    align-self: center;
    margin-top: 100px;
    flex: 1 0 auto;
}

.accepted-wrapper .accepted-big-footer-wrapper {
    flex-shrink: 0;
}

.accepted-content .content-block {
    width: 1200px;
}

.accepted-content .first-block-with-border {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 82px;
    align-items: center;
    border-width: 3px 3px 3px 3px;
    border-style: solid;
    border-color: #808080;
    border-radius: 24px 24px 0px 0px;
    width: 1200px;
    height: 550px;
    box-sizing: border-box;
    padding: 0px 63px 0px 27px;
}

.accepted-content .second-block-with-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    border-width: 0px 3px 3px 3px;
    border-style: solid;
    border-color: #808080;
    border-radius: 0px 0px 24px 24px;
    text-align: left;
    padding: 34px 75px;
    box-sizing: border-box;
}

.accepted-content .left-block {
    margin-top: 33px;
}

.accepted-content .right-block {
    text-align: left;
}

.accepted-content .ribbon {
    position: absolute;
    top: 42px;
    left: 92px;
}

.accepted-content .car-name {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #2A2F31;
}

.accepted-content .company-name {
    align-items: center;
    gap: 4px;
    display: flex;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
}

.accepted-content .price-block {
    margin-top: 11px;
    font-weight: 700;
    font-size: 128px;
    line-height: 128px;
    letter-spacing: -0.015em;
    color: #2BACE2;
}

.accepted-content .price-block .dollar {
    margin-bottom: 24px;
}

.accepted-content .timer-block {
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #E3342F;
}

.accepted-content .information-block {
    margin-top: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.accepted-content .type-of-information {
    display: inline-block;
    margin-right: 8px;
    font-weight: 700;
}

.accepted-content .next-steps-title {
    margin-top: 40px;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.02em;
}

.accepted-content .next-steps-text {
    margin-top: 11px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.003em;
}

.accepted-content .items-steps {
    margin-left: 20px;
    margin-top: -10px;
}

.accepted-content .item-step {
    gap: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    margin-bottom: 2px;
    /* display: flex; */
    align-items: start;
}

.accepted-content .next-steps-block {
    text-align: left;
    margin-left: 20px;
}