.time-select {
  width: 100%;
  position: relative;
  display: inline-block;
}

.time-select .errorImg {
  top: 12px;
}

.time-select .MuiSvgIcon-root {
  display: none;
}

.time-select label.MuiInputLabel-root {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #B3B3B3;
  margin-left: 8px;
  font-family: 'Montserrat', sans-serif;
  margin-top: -2px;
}

.time-select .MuiInputLabel-root.MuiInputLabel-shrink {
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #808080;
  margin-left: 8px;
  transform: translate(12px, 6px) scale(0.8);
}

.time-select .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused {
  color: #2BACE2;
}

.time-select .MuiSelect-select{
  height: 54px;
  min-height: 54px !important;
  background-color: #FFFFFF;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 21px 20px 7px;
  font-size: 18px;
  color: currentColor;
}

.time-select .Mui-focused .MuiSelect-select {
  border-color: #2BACE2;
  border-width: 2px;
  padding: 20px 19px 6px;
}

.time-select .MuiOutlinedInput-notchedOutline{
  display: none;
}

.time-select .Mui-disabled .MuiSelect-select{
  background-color: #E6E6E6;
}